"use client";

import { ConfigContext } from "@/context/ConfigContext";
import AuthProvider from "@/providers/AuthProvider";
import { TypeChildrenOnly } from "@/types";
import { providers, ProviderStack } from "@opencreek/provider-stack";
import { Theme } from "@radix-ui/themes";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import * as React from "react";


type AllProvidersProps = {
  children: React.ReactNode
};


const providersStack = providers()
  .add(NextThemesProvider, {
    attribute: "class",
    defaultTheme: "system",
    // forcedTheme: "light",
    enableSystem: true,
    disableTransitionOnChange: true,
  })
  .add(Theme, {
    radius: 'medium'
  })
  .add(ConfigContext.Provider, {
    value: {
      IS_DEVELOP: process.env.NODE_ENV === 'development',
    },
  })
  .add(AuthProvider);



function SafeHydrate({ children }: TypeChildrenOnly) {
  return (
    <div suppressHydrationWarning>
      {typeof window === "undefined" ? null : children}
    </div>
  );
}



export default function ClientProviders({ children }: AllProvidersProps) {
  return (
    <ProviderStack providers={providersStack}>
      <SafeHydrate>
        {children}
      </SafeHydrate>
    </ProviderStack>
  );
}
