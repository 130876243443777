import MiniLayout from "@/app/(mini)/layout";
import SignInForm from "@/forms/SignInForm";
import { Flex, Heading } from "@radix-ui/themes";
import React from "react";

export default function AuthPage() {
  return (
    <MiniLayout>
      <Flex direction={"column"} gap="8">
        <Heading as={"h1"} style={{ fontSize: "5em" }}>TG Index</Heading>
        <SignInForm></SignInForm>
      </Flex>
    </MiniLayout>
  );
}




