"use client";

import { auth } from "@/client/Auth";
import AuthPage from "@/special/AuthPage";
import GloablPreloadPage from "@/special/GlobalPreloadPage";
import React from "react";
import { useObservable } from "react-use";

const observable = auth().authorized$;
observable.subscribe((val) => {
  console.log('authorized$ 1', val);
  // noop, без явного subscribe иногда зависает, если useObservable подписывается слишком поздно
})


type TypeProps = Readonly<{
  children?: React.ReactNode;
}>;
export default function AuthProvider({ children }: TypeProps) {
  const authorized = useObservable(observable);
  // console.log('AuthProvider: authorized=', authorized);
  if (authorized === undefined) {
    return (<GloablPreloadPage/>);
  }
  if (!authorized) {
    return (<AuthPage/>);
  }
  return children;
}


