"use client";

import { auth } from "@/client/Auth";
import TGButton from "@/components/TgButton";
import { ConfigContext } from "@/context/ConfigContext";
import "@/styles/form.css";
import { ITelegramUser } from "@/types";
import { Button, Flex } from "@radix-ui/themes";
import React, { useContext } from "react";


type TypeProps = Readonly<{
  // children?: React.ReactNode;
  onTgAuth?: (user: ITelegramUser) => void
}>;


export default function SignInForm({}: TypeProps) {

  const config = useContext(ConfigContext);
  const showFakeLogin = config.IS_DEVELOP;

  console.log('showFakeLogin', showFakeLogin);

  const onTgAuth = async (telegramUser: ITelegramUser) => {
    try {
      await auth().loginTg(telegramUser);
    } catch (e) {
      alert(e);
    }
  };


  return (
    <Flex direction={"column"} align={"center"}>
      <TGButton onTgAuth={onTgAuth} />
      {showFakeLogin ? <Button onClick={() => auth().fakeLogin()}> test login</Button> : ''}
    </Flex>
  );
}
