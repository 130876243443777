"use client";

import { ITelegramUser } from "@/types";
import { Button, Skeleton } from "@radix-ui/themes";
import React, { useEffect } from "react";

type TypeProps = Readonly<{
  // children?: React.ReactNode;
  onTgAuth: (user: ITelegramUser) => void
}>;



declare global {
  interface Window {
    onTelegramAuth: (user: ITelegramUser) => void;
  }
}


export default function TGButton({onTgAuth}: TypeProps) {


  const wrapper = React.useRef<HTMLDivElement>(null);
  const preloader = React.useRef<HTMLDivElement>(null);
  const getWrapper = (): HTMLDivElement | null => {
    if (wrapper.current) {
      return wrapper.current;
    }
    return null;
  };



  useEffect(() => {

    window.onTelegramAuth = function(user) {
      onTgAuth(user);
    };

    const button: HTMLScriptElement = document.createElement("script");
    button.async = true;
    button.src = "https://telegram.org/js/telegram-widget.js?22";
    button.setAttribute("data-telegram-login", "tgindexapp_bot");
    button.setAttribute("data-size", "large");
    button.setAttribute("data-request-access", "write");
    button.setAttribute("data-onauth", "onTelegramAuth(user)");
    button.onload = function(){
      preloader.current?.remove();
    }

    getWrapper()?.appendChild(button);
    return () => {
      getWrapper()?.removeChild(button);
    };
  }, [onTgAuth]);


  return (
    <div ref={wrapper} >
      <Skeleton ref={preloader}>
        <Button radius={'full'} size={"3"}>login as username</Button>
      </Skeleton>
    </div>
  );
}
