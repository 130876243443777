import { Box, Flex } from "@radix-ui/themes";
import React from "react";


export default function MiniLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {


  return (
    <Flex justify={"center"} align={"center"} direction={"column"} className="MainWrapper">
      <Box style={{maxWidth:"50%"}}>
      {children}
      </Box>
    </Flex>
  );
}

